import React from 'react';
import { Link, graphql } from 'gatsby';
import BoxRows from 'components/box/box-rows/box-rows';
import Img from 'gatsby-image';
import { FullWidthImg } from 'components/image-wrappers';
import Title from 'components/title';
import Layout from 'components/layout';
import Stack from 'components/stack';
import StackItem from 'components/stack/stack-item';
import {
  TextWrapper,
  TextUnderline,
  Body,
  Description,
  StickyText,
  ProjectBox,
  ProjectInfo,
  BackgroundText,
  NavigationWrapper,
  NavButtons,
  NavSubText,
  ImgTitle,
  ImgWrapper,
  HeroImgContainer,
  BodyImgWrapper
} from 'components/GlobalComponents';


const MentalModelUX = ({ data }) => {
  const project = data.mentalmodelJson;

  return (
    <Layout>
      <HeroImgContainer>
        <FullWidthImg
          key={project.gallery.hero.id}
          opacity={0.1}
          fluid={project.gallery.hero.childImageSharp.fluid}
        />
        <ImgTitle>{project.title}</ImgTitle>
      </HeroImgContainer>
    <TextWrapper>
      <BoxRows>
        <Stack>
          <StackItem>
            <Title as="h4">ROLE</Title>
            <BackgroundText>{project.role}</BackgroundText>
          </StackItem>
          <StackItem>
            <Title as="h4">CONTEXT</Title>
            <BackgroundText>{project.context}</BackgroundText>
          </StackItem>
          <StackItem>
            <Title as="h4">YEAR</Title>
            <BackgroundText>{project.year}</BackgroundText>
          </StackItem>
          <StackItem>
            <Title as="h4">METHODS</Title>
            <BackgroundText
              dangerouslySetInnerHTML={{
                __html: project.methods.childMarkdownRemark.html,
              }}
            ></BackgroundText>
          </StackItem>
        </Stack>
        <ProjectInfo>
          <Title as="h3">Overview</Title>
          <TextUnderline />

          <Description
            dangerouslySetInnerHTML={{
              __html: project.overview.childMarkdownRemark.html,
            }}
          />
          <Title as="h4">Research Goals and Questions:</Title>
          <TextUnderline />
          <Description
            dangerouslySetInnerHTML={{
              __html: project.goals.childMarkdownRemark.html,
            }}
          />
        </ProjectInfo>
      </BoxRows>

      <ProjectBox>
        <Title as="h4">Introduction</Title>
        <TextUnderline />
        <ProjectInfo>
          <Description
            dangerouslySetInnerHTML={{
              __html: project.introduction.childMarkdownRemark.html,
            }}
          />
        </ProjectInfo>

        <ImgWrapper>
          <Img
            fluid={project.gallery.segment1.childImageSharp.fluid}
          />
        </ImgWrapper>
        <ImgWrapper>
          <Img
            fluid={project.gallery.segment2.childImageSharp.fluid}
          />
        </ImgWrapper>
      </ProjectBox>
      <ProjectBox>
        <Title as="h4">TO BE CONTINUED</Title>
        <TextUnderline />
      </ProjectBox>
    </TextWrapper>

    </Layout>
  );
}

export const query = graphql`
  query MentalModelQuery {
    mentalmodelJson {
      title
      year
      role
      context
      methods {
        childMarkdownRemark {
          html
        }
      }
      overview {
        childMarkdownRemark {
          html
        }
      }
      goals {
        childMarkdownRemark {
          html
        }
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      gallery {
        hero {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        segment1 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        segment2 {
          childImageSharp {
            fluid(maxWidth: 850, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default MentalModelUX;
